/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Menu Václavák"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-54zcwo --style2 --full pb--60 pt--60" name={"h0zyvo88cq"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Menu - Václavské náměstí"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wx0zls4fs7"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":395,"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box fs--48" content={"Horké nápoje"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Káva</span>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" style={{"maxWidth":390}} content={"Espresso / Double - 55 Kč / 85 Kč<br>Lungo - 55 Kč<br>Espresso Macchiato - 59 Kč<br>Cappuccino - 75 Kč<br>Caffe Latte - 79 Kč<br>Flat White - 89 Kč<br>Americano - 85 Kč<br>Affogato (zmrzlina, espresso) - 79 Kč<br>Espresso &amp; Tonic - 89 Kč&nbsp;<br>Vídeňská káva - 85 Kč&nbsp;<br>Irish Coffee - 135 Kč<br>Drip (300/500ml) - 85/99 Kč<br>Aeropress (200ml) - 89 Kč&nbsp;<br>"}>
              </Text>

              <Title className="title-box fs--48 mt--80 pt--20" content={"Ostatní nápoje"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Nealko nápoje</span>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" style={{"maxWidth":1000}} content={"Naše domácí limonády (0,4l) - 85 Kč&nbsp;<br>Čerstvý džus (0,3l) - 89 Kč<br>Citronová Matcha (0,2l) - 89 Kč<br>Thomas Henry (tonic, pink grapefruit) - 65 Kč<br>Royal Crown Cola (0,25l) - 59 Kč&nbsp;<br>Rajec (0,33l) - 55 Kč<br>Minerální voda Radenská (0,25l) - 55 Kč<br>Filtrovaná voda s citronem (0,35l) - 22 Kč<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Míchané nápoje</span>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Aperol Spritz - 129 Kč<br>Cuba Libre - 135 Kč<br>Gin Tonic - 135 Kč<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":455}}>
              
              <Title className="title-box" content={""}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: 700;\">Teplé nápoje</span><br>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Čaj z čerstvé máty nebo zázvoru s medem - 89 Kč<br>Čaj porcovaný (dle nabídky) - 75 Kč<br>Indian Chai Latte - 99 Kč<br>Matcha Latte - 89 Kč<br>Horká čokoláda - 79 Kč<br>Svařené víno - 85 Kč<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box pt--60" content={"<span style=\"font-weight: bold;\"><br><br><br><br><br><br><br><br><br>Víno</span>"}>
              </Subtitle>

              <Text className="text-box fs--14" style={{"maxWidth":"","marginTop":6.171875}} content={"Ryzlink Rýnský (0,15l/0,75l) - 89 Kč / 389 Kč<br>Pinot Grigio (0,15l/0,75l) - 95 Kč / 465 Kč<br>Rosé Svatovavřinecké (0,15l/0,75l) - 89 Kč / 385 Kč<br>Reservé de L'aube Rouge (0,15l/0,75l) - 80 Kč / 415 Kč<br>Montepulciano Moda&nbsp;(0,15l/0,75l) - 95 Kč / 485 Kč<br>Terra Vizina Prossecco (0,15l/0,75l) - 95 Kč / 435 Kč<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Pivo</span>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Pilsner Urquell (0,33l) - 59 Kč<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Alkohol</span>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Campari Bitter - 85 Kč<br>Graham's Porto Ruby - 89 Kč<br>Martini - 89 Kč<br>Bailey's - 89 Kč<br>Jägermeister - 89 Kč<br>Becherovka - 85 Kč<br>Grenall's - 85 Kč<br>Rebellion - 89 Kč<br>Flor de Caña - 89 Kč<br>Hell or High Water XO Rum 40% - 139 Kč<br>Jameson - 89 Kč<br>Glenfiddich - 145 Kč<br>Hennessy V.S. - 99 Kč<br>Russian Standard - 99 Kč<br>Hildprandt - 99 Kč<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4" style={{"maxWidth":410,"backgroundColor":"rgba(112,156,159,1)"}}>
              
              <Title className="title-box fs--48 pl--06" content={"<span style=\"color: rgb(255, 255, 255);\">Bistro</span><br>"}>
              </Title>

              <Text className="text-box fs--14 pl--25 pr--25" style={{"maxWidth":635}} content={"<span style=\"color: rgb(255, 255, 255);\">Toast s mozzarelou, rajčaty, domácím pestem a salátem -&nbsp; 115 Kč<br><br>Toast se šunkou, cheddarem, rajčaty a salátem - 119 Kč<br><br>Wrap s kozím sýrem, sušenými rajčaty a domácím malinovým chutney - 139 Kč<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}